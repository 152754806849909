/* src/components/styles/ContactForm.css */

/* Default styles for the iframe */
iframe {
    width: 100%;
    height: 110vh; /* Full viewport height */
    border: none;
    overflow: hidden;
    min-height: 650px; /* Minimum height to prevent scrolling on smaller devices */
}

/* Media query for tablets (max-width: 768px) */
@media only screen and (max-width: 768px) {
    iframe {
        height: 800px; /* Adjust height for tablets */
    }
}

/* Media query for mobile devices (max-width: 480px) */
@media only screen and (max-width: 480px) {
    iframe {
        height: 1000px; /* Adjust height for mobile devices */
    }
}
